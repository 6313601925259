<template>
  <div>
    <loading></loading>
  </div>
</template>

<script>
import https from "@/apis/liff/v2/https";
import liff from "@line/liff";
import deepGet from "lodash/get";

export default {
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      // Get Template Data
      try {
        const org = this.$route.params.org_code;
        const handler = this.$route.params.handler;
        const params = new URLSearchParams(this.$route.query).toString();

        await this.execShareTargetPicker(deepGet(
          await https.get(`${org}/liff/share_picker/${handler}?${params}`),
          'data.data'
        ));
      } catch (e) {
        alert('發生錯誤，無法進行分享');
        console.error(e);
      }
    },
    async execShareTargetPicker(message) {
      if (liff.isApiAvailable('shareTargetPicker')) {
        liff.shareTargetPicker([message], { isMultiple: true })
          .then(function () {
            liff.closeWindow();
          })
          .catch(function (error) {
            console.error(error);
          });
      } else {
        alert('此 LINE 帳號尚未開啟 Share Target Picker 功能');
        console.log('share target picker api not available.');
      }
    }
  }
}
</script>
